

body {
  
  background-image: url("./components/bk.png") ;
  background-size:auto ;

}

.pages {
  color: #316685;
  text-align: center;
  font-size: 3.5rem;
  margin-top: 20px;
}



.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
}