/* Base styles for all screen sizes */
.body {
  background-color: #0e0e10;
  color: #ffffff;
}

.container {
  max-width: 1000px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #000000; /* Add this line for a visible border */
  padding: 20px; /* Optional: add padding for better layout */
  background-color: rgba(14, 14, 16, 0.6);
}

.container-text {
  font-size: 16px;
  line-height: 1.8;
  margin-top: 20px;
  color: #dddddd;
  border-radius: 10px;
  padding: 30px;
  background-size: contain; 
  background-repeat: no-repeat;
  background-position: right;

  background-color:#000000
}

.milestone{
  padding-top: 10px;
  /*display: flex;  If you want to add more, uncoment, Flex box will place all items from right to left*/
  padding-left: 20px;
  
}

/* STAFF only */ 

.staff-section {
  text-align: center;
}

.staff-list {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
}

.staff-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px; /* Fixed width for consistency */
  max-width: 150px; /* Optional: set a maximum width */
  text-align: center;
}

.staff-img {
  width: auto;
  max-height: 100px;
  border-radius: 50%;
  margin-bottom: 8px;
  border: 2px solid #dddddd;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.staff-img:hover {
  transform: scale(1.1); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.staff-desc {
  font-size: 1em;
  color: #666;
  text-align: center;
  margin-top: 5px;
  max-width: 200px; /* Ensures text wraps within this width */
  
}

/* Prog */

.achievement-img {
  width: 100%;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.achievement-img:hover {
  transform: scale(1.05);
}


.pages {
  color: #2e95f7;
  text-shadow: 2px 2px 4px rgba(132, 132, 132, 0.5);
  text-align: center;
  font-size: 1.8rem;
}

.header {
  display: flex;
  align-items: center; 
}

.logo img.home-img {
  width: 300px; 
}

.title {
  flex: 1; /* Allow the title to grow to fill available space */
}

.footer {
  
  color: #fff;
  padding: 20px 0;
  text-align: center;
  font-size: 20px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.social-links a {
  color: #fff;
  margin: 0 10px;
  font-size: 20px;
}

.social-links a:hover {
  color: #ccc;
}

.social-icons {
  display: flex;
  justify-content: center; /* Center the icons horizontally */
}

.social-icons a {
  color: #fff;
  margin: 0 10px; /* Add margin to create space between icons */
  font-size: 24px;
}

li {
  text-align: left; /* Align text to the right */
}

.social-icons a:hover {
  color: #ccc;
}

/* Media query for screens smaller than 768px */
@media only screen and (max-width: 768px) {
  .header {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start of the container */
  }
  
  .logo,
  .title {
    width: 100%; /* Make each item take up full width */
  }
}
