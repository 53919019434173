.navbar {
  background-color: #000000;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
 
  
  z-index: 20;
  
}

.nav-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1500px;
  position: relative;
}

.nav-menu {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  color: #000000;
  width: 100%;
  z-index: 10;
  transform: translateY(-10px);
  transition: opacity 2s ease, transform 0.9s ease;
  text-align: left;
  max-width: calc(100vw - 20px);
  overflow-x: auto;
  background-color: rgba(14, 14, 16, 0.6); /* 80% opacity */
}

.nav-logo {
  color: #f3ece3;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
  display: flex;
}

.nav-logo .icon {
  width: 3rem;
  height: 3rem;
  margin-left: 16px;
}

.nav-icon {
  color: #f3ece3;
  cursor: pointer;
  display: flex;
  align-items: center;
  order: -1;
  padding-left: 30px;
}

.nav-icon .icon {
  width: 2rem;
  height: 2rem;
}

.nav-menu.active {
  display: flex;
}

.nav-item {
  margin: 10px;
  padding-left: 10px;
  color: #000000;
  animation: fadeIn 2.0s ease forwards;
  list-style-type: none; 
}

.nav-item:last-child {
  margin-right: 0;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: flex;
  transition: color 0.3s ease;
  color: #ffffff;
  transition: color 0.3s ease;
}

.nav-links:hover {
  color: #ff6f61;
}

.menu-title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  padding: 10px 20px;
  border: 2px solid #ffffff;
  border-radius: 5px;
  background-color: transparent;
  transition: all 1s ease;
  cursor: pointer;
}

.menu-title:hover {
  background-color: #ffffff;
  color: #333333;
}

.menu-title:active {
  transform: scale(0.95);
  background-color: #555555;
  border-color: #555555;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
